import './App.css';

import logo from './logo.png';

import soul from './soul.jpg';
import grail from './grail.jpg';
import prime from './prime.jpg';

import twit from './twitter.png';
import insta from './instagram.png';
import email from './mail.png';

const date =  new Date().getFullYear();

function App() {
  return (
    <div className="container mx-auto p-4 items-center w-full">    
      <div className="navbar bg-base-100 bg-neutral text-neutral-content">
        <div className="navbar-start">
        <button className="btn btn-square btn-ghost">
          <img src={logo} alt="logo" />
        </button>
      </div>
      <div className="navbar-center lg:flex">
        <h1 className="title btn btn-ghost normal-case prose md:prose-md lg:prose-2xl font-link">
          Massive Resources Corporation
        </h1>
      </div>
      <div className="navbar-end">
      </div>
    </div>
    <br />
    <div className="card lg:card-side bg-base-100 shadow-xl">
      <figure><img src={grail} alt="holy grail"/></figure>
      <div className="card-body">
        <h2 className="card-title">Holy Grail on Sunnyvale</h2>
        <p>
          This stylish place will take your breath away. Here you will be left spellbound with every room and you'll feel like you are living in the fantasy of the Metaverse. Whether you're in the game room shooting pool or playing arcade games, this home will feel just like heaven.
        </p>
        <div className="card-actions justify-end">
          <a 
            href="https://www.vrbo.com/3091992?unitId=3665066" 
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary"
          >
            VRBO
          </a>
          <a 
            href="https://abnb.me/zwiNisqXXub" 
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary"
          >
            Airbnb
          </a>
        </div>
      </div>
    </div>
    <br />
    <div className="card lg:card-side bg-base-100 shadow-xl">
      <figure><img src={soul} alt="soul of serenity" /></figure>
      <div className="card-body">
        <h2 className="card-title">The Soul Of Serenity</h2>
        <p>
          Soul Of Serenity is for your art hearts. A great spot for work, play or production with its own dedicateed workspace. This comfy two bedroom house with a large deck and backyard is perfect for all your family and group stays. Comfortable and great for relaxing!
        </p>
        <div className="card-actions justify-end">
          <a 
            href="https://www.airbnb.com/rooms/738032423775754924?guests=1&adults=1&s=67&unique_share_id=7fe91bd0-62ea-4d8c-b58a-b13fde4910fe" 
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary"
          >
            Airbnb
          </a>
          <a 
            href="https://www.zillow.com/homedetails/156-S-Canton-Club-Cir-Jackson-MS-39211/3058180_zpid/?utm_campaign=iosappmessage&utm_medium=referral&utm_source=txtshare" 
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary"
          >
            Zillow
          </a>
        </div>
      </div>
    </div>
    <br />
    <div className="card lg:card-side bg-base-100 shadow-xl">
      <figure><img src={prime} alt="prime time"/></figure>
      <div className="card-body">
        <h2 className="card-title">Prime Time Tiger&apos;s Den</h2>
        <p>
          Relax with the whole family at this peaceful place to stay. Perfect for college stays and military groups. 1.2 Miles from Jackson State University. A great spot for work or play or family outings. Centrally located easy access to all of Jackson and surrounding areas.
        </p>
        <div className="card-actions justify-end">
          <a 
            href="https://www.vrbo.com/2927007?unitId=3499047" 
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary"
          >
            VRBO
          </a>
          <a 
            href="https://abnb.me/3Er4mRrXXub" 
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary"
          >
            Airbnb
          </a>
        </div>
      </div>
    </div>
    <br />
    <footer className="footer items-center p-4 bg-neutral text-neutral-content">
      <div className="items-center grid-flow-col">
        <p>Massive Resources Corp. Copyright © {date}</p>
      </div> 
      <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
      <a href="mailto:massiveresourcescorporation@gmail.com"><img src={email} alt="contact us" /></a>
        <a href="https://twitter.com/massiverc"><img src={twit} width="30" height="30" alt="follow us on twitter" /></a>
        <a href="https://www.instagram.com/massiveresources/?hl=en"><img src={insta} alt="follow us on instagram" width="30" height="30" /></a>
      </div>
    </footer>
  </div>
  );
}

export default App;
